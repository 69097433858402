export default [
  {
    path: '/tickets/levantamiento-inicial',
    name: 'levantamiento-inicial',
    component: () => import('@/views/postes/migracionPuntos/Listado.vue'),
    meta: {
      pageTitle: 'Levantamiento Inicial',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets Levantamientos Ingresados',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/levantamientos-asignados',
    name: 'levantamientos-asignados',
    component: () => import('@/views/levantamiento-inicial/levantamiento-asignadas-index.vue'),
    meta: {
      pageTitle: 'Levantamiento Inicial',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets de Levantamiento Asignados',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/levantamientos-instalacion',
    name: 'levantamientos-instalacion',
    component: () => import('@/views/levantamiento-inicial/levantamiento-instalacion-index.vue'),
    meta: {
      pageTitle: 'Levantamiento Inicial',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets de Levantamientos en Instalación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/levantamientos-migrados',
    name: 'levantamientos-migrados',
    component: () => import('@/views/levantamiento-inicial/levantamiento-migradas-index.vue'),
    meta: {
      pageTitle: 'Levantamiento Inicial',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets de Levantamientos Migrados',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/levantamientos-finalizados',
    name: 'levantamientos-finalizados',
    component: () => import('@/views/levantamiento-inicial/levantamiento-verificadas-index.vue'),
    meta: {
      pageTitle: 'Levantamiento Inicial',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets de Levantamientos Finalizados',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laboratorio/luminarias',
    name: 'administrador-luminarias',
    component: () => import('@/views/laboratorio/luminarias/Index.vue'),
    meta: {
      pageTitle: 'Luminarias',
      resource: 'BODEGA',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Gestión de Luminarias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laboratorio-apvn/luminarias',
    name: 'administrador-luminarias-apvn',
    component: () => import('@/views/laboratorio/luminarias/Index.vue'),
    meta: {
      pageTitle: 'Luminarias',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Gestión de Luminarias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/laboratorio/nemas',
    name: 'administrador-nemas',
    component: () => import('@/views/laboratorio/nemas/Index.vue'),
    meta: {
      pageTitle: 'Nemas',
      resource: 'BODEGA',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Gestión de Nemas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bodega/postes',
    name: 'inventario-postes',
    component: () => import('@/views/bodega/postes/index.vue'),
    meta: {
      pageTitle: 'Bodega',
      resource: 'BODEGA',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inventario de Postes',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bodega/brazos',
    name: 'inventario-brazos',
    component: () => import('@/views/bodega/brazos/index.vue'),
    meta: {
      pageTitle: 'Bodega',
      resource: 'BODEGA',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inventario de Brazos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bodega/herrajes',
    name: 'inventario-herrajes',
    component: () => import('@/views/bodega/herrajes/index.vue'),
    meta: {
      pageTitle: 'Bodega',
      resource: 'BODEGA',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inventario de Herrajes',
          active: true,
        },
      ],
    },
  },
]
