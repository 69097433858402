export default [
  {
    path: '/aplomado-poste',
    name: 'aplomado-poste',
    component: () => import('@/views/mantenimiento/aplomadoPoste/aplomadoPosteIndex.vue'),
    meta: {
      pageTitle: 'Aplomado de Poste',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/aplomado-poste-formulario/:poste',
    name: 'aplomado-poste-formulario',
    component: () => import('@/views/mantenimiento/aplomadoPoste/aplomadoPosteFormulario.vue'),
    meta: {
      navActiveLink: 'aplomado-poste',
      pageTitle: 'Aplomado de Poste Formulario',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/edicion-aplomado-poste',
    name: 'edicion-aplomado-poste',
    component: () => import('@/views/mantenimiento/aplomadoPoste/aplomadoPosteEdit.vue'),
    meta: {
      pageTitle: 'Edición del Aplomado de Poste',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/seguimiento-aplomado',
    name: 'seguimiento-aplomado',
    component: () => import('@/views/mantenimiento/aplomadoPoste/AplomadoTabs.vue'),
    meta: {
      pageTitle: 'Seguimiento de Aplomado',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
