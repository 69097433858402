export default [
  {
    path: '/conservacion-gateway',
    name: 'conservacion-gateway',
    component: () => import('@/views/mantenimiento/conservacionGateway/conservacionGatewayIndex.vue'),
    meta: {
      pageTitle: 'Conservación de Gateway',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/conservacion-gateway-formulario/:gateway',
    name: 'conservacion-gateway-formulario',
    component: () => import('@/views/mantenimiento/conservacionGateway/conservacionGatewayFormulario.vue'),
    meta: {
      navActiveLink: 'conservacion-gateway',
      pageTitle: 'Conservación de Gateway Formulario',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
