export default [
  {
    path: '/falla-linea-bt',
    name: 'falla-linea-bt',
    component: () => import('@/views/mantenimiento/fallaLineaBt/fallaBtIndex.vue'),
    meta: {
      pageTitle: 'Falla de Lineas BT',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/formulario-falla-linea-bt',
    name: 'formulario-falla-linea-bt',
    component: () => import('@/views/mantenimiento/fallaLineaBt/fallasBtFormulario.vue'),
    meta: {
      navActiveLink: 'falla-linea-bt',
      pageTitle: 'Ingreso de Falla Linea BT',
      resource: 'TECNICO',
      action: 'manage',
    },
  },
  {
    path: '/edicion-falla-bt/:id',
    name: 'edicion-falla-bt',
    component: () => import('@/views/mantenimiento/fallaLineaBt/edicionFallasBt.vue'),
    meta: {
      navActiveLink: 'falla-linea-bt',
      pageTitle: 'Edición de Linea Falla BT',
      resource: 'ADMIN',
      action: 'manage',
    },
  },
]
