export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      resource: 'ANY',
      action: 'manage',
      layout: 'full',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/Error.vue'),
    meta: {
      resource: 'ANY',
      action: 'manage',
      layout: 'full',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/error/NotAutorized.vue'),
    meta: {
      resource: 'ANY',
      action: 'manage',
      layout: 'full',
    },
  },
]
