export default [
  {
    path: '/coordinacion-aseguradora',
    name: 'coordinacion-aseguradora',
    component: () => import('@/views/mantenimiento/coordinacionAseguradora/coordinacion-aseguradora-index.vue'),
    meta: {
      pageTitle: 'Coordinación Aseguradora',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/coordinacion-aseguradora-formulario/:poste',
    name: 'coordinacion-aseguradora-formulario',
    component: () => import('@/views/mantenimiento/coordinacionAseguradora/coordinacion-formulario.vue'),
    meta: {
      navActiveLink: 'coordinacion-aseguradora',
      pageTitle: 'Coordinación Aseguradora',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/detalle-coordinacion-aseguradora/:id',
    name: 'detalle-coordinacion-aseguradora',
    component: () => import('@/views/mantenimiento/coordinacionAseguradora/coordinacionAseguradoraViews/coordinacion-aseguradora-detalles.vue'),
    meta: {
      navActiveLink: 'coordinacion-aseguradora',
      pageTitle: 'Detalle de Coordinación Aseguradora',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/levantamientoMobile',
    name: 'levantamientoMobile',
    component: () => import('@/views/levantamiento/levantamientoMobile.vue'),
    meta: {
      pageTitle: 'Levantamiento Datos',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
