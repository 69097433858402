export default [
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/tickets/Tickets.vue'),
    meta: {
      pageTitle: 'Tickets',
      resource: 'CORRECTIVO',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ticket',
    name: 'ticket-web',
    component: () => import('@/views/tickets/TicketWeb.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/fallas',
    name: 'fallas',
    component: () => import('@/views/tickets/TicketsTecnico.vue'),
    meta: {
      pageTitle: 'Tickets',
      resource: 'TCORRECTIVO',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tickets',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets/inspeccion/:id/:tipo',
    name: 'tickets-inspeccion-search',
    component: () => import('@/views/tickets/IngresoFallaInspeccion.vue'),
    meta: {
      pageTitle: 'Ingreso de Falla',
      resource: 'INSPECCIONES',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
