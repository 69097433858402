export default [
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('@/views/administracion/Usuarios/Usuarios.vue'),
    meta: {
      pageTitle: 'Usuarios',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/usuarios/show/:id/:origen/:action',
    name: 'catalogos-usuario-show',
    component: () => import('@/views/administracion/Usuarios/DetailUsuario.vue'),
    meta: {
      pageTitle: 'Detalle del Usuario',
      navActiveLink: 'usuarios',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Usuarios',
          url: '/usuarios',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
]
