export default [
  {
    path: '/mantenimiento-bodega',
    name: 'mantenimiento-bodega',
    component: () => import('@/views/mantenimiento/bodega/mantenimientoBodega.vue'),
    meta: {
      pageTitle: 'Mantenimiento de Bodega',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/recepcion-bodega/:poste?',
    name: 'recepcion-bodega',
    component: () => import('@/views/mantenimiento/bodega/formulario-bodega.vue'),
    meta: {
      navActiveLink: 'mantenimiento-bodega',
      pageTitle: 'Formulario de Ingreso de Luminaria',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/escaner',
    name: 'escaner',
    component: () => import('@/components/mantenimiento/bodega/escaner.vue'),
    meta: {
      pageTitle: 'Escaner',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  // DESPACHOS DE LUMINARIAS APVN
  {
    path: '/despacho-luminarias',
    name: 'despacho-luminarias',
    component: () => import('@/views/despachos/luminarias/despacho-luminarias-index.vue'),
    meta: {
      pageTitle: 'Despacho de Luminarias',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
