export default [
  {
    path: '/brecha-iluminacion',
    name: 'brecha-iluminacion',
    component: () => import('@/views/mantenimiento/brechaIluminacion/brechaIluminacionIndex.vue'),
    meta: {
      pageTitle: 'Brecha Área de Iluminación',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/brecha-iluminacion-formulario/:brecha',
    name: 'brecha-iluminacion-formulario',
    component: () => import('@/views/mantenimiento/brechaIluminacion/brechaIluminacionFormulario.vue'),
    meta: {
      navActiveLink: 'brecha-iluminacion',
      pageTitle: 'Brecha Área de Iluminación Formulario',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
