import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
import mantenimientoBodega from '@/router/pages/mantenimientoBodega/mantenimientoBodega'
import inspecciones from '@/router/pages/inspecciones'
import tickets from '@/router/pages/tickets'
import brechaIluminacion from '@/router/pages/mantenimientoPreventivo/brechaIluminacion'
import aplomadoPoste from '@/router/pages/mantenimientoPreventivo/aplomadoPoste'
import conservacion from '@/router/pages/mantenimientoPreventivo/conservacion'
import conservacionGatewayRoutes from '@/router/pages/mantenimientoPreventivo/conservacionGatewayRoutes'
import coordinacionAseguradoraRoutes from '@/router/pages/mantenimientoPreventivo/coordinacionAseguradoraRoutes'
import conservacionGabinetes from '@/router/pages/mantenimientoPreventivo/conservacionGabinetes'
import levantamientoInicial from '@/router/pages/levantamientoInicial'
import fallaBt from '@/router/pages/mantenimientoPreventivo/fallaBt'
import informeMensual from '@/router/pages/mantenimientoPreventivo/informe-mensual'
import { hasPassedOneDay } from '@/utils/auth'
// import { datadogRum } from '@datadog/browser-rum'
import errores from './pages/errores'
import auth from './pages/auth'
import reports from './pages/reports'
import usuarios from './pages/usuarios'
import administracion from './pages/administracion'
import tecnico from './pages/mantenimientoCorrectivo/apmix/tecnico'
import bodega from './pages/bodega/bodega'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...tecnico,
    ...reports,
    ...auth,
    ...administracion,
    ...errores,
    ...usuarios,
    ...inspecciones,
    ...tickets,
    ...fallaBt,
    ...brechaIluminacion,
    ...aplomadoPoste,
    ...conservacion,
    ...conservacionGatewayRoutes,
    ...coordinacionAseguradoraRoutes,
    ...conservacionGabinetes,
    ...informeMensual,
    ...mantenimientoBodega,
    ...levantamientoInicial,
    ...bodega,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line no-unused-vars
let isLogoutInProgress = false // Variable de estado

router.beforeEach((to, _, next) => {
  if (isLogoutInProgress) {
    isLogoutInProgress = false
    return next()
  }

  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()

  if (!userData || hasPassedOneDay(localStorage.getItem('loginFecha'))) {
    isLogoutInProgress = true
    localStorage.removeItem('userData')
    // datadogRum.stopSessionReplayRecording()
    if (to.name !== 'auth-login') {
      return next({ name: 'auth-login' })
    }
    // Si ya estamos en la página de inicio de sesión, simplemente finalizamos el proceso de cierre de sesión
    isLogoutInProgress = false
    return next()
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (isLoggedIn && to.name === 'auth-login') {
      return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    if (to.name === 'home' && userData.role === 'INSPECCIONES') {
      return next({ name: 'inspecciones-nocturnas' })
    }
    if (to.name === 'home' && userData.role === 'MUNICIPALIDAD') {
      return next({ name: 'inspecciones-autorizaciones' })
    }
    if (to.name === 'home' && userData.role === 'TECNICO') {
      return next({ name: 'fallas' })
    }
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  isLogoutInProgress = false
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
