export default [
  {
    path: '/conservacion',
    name: 'conservacion',
    component: () => import('@/views/mantenimiento/conservacion/conservacionIndex.vue'),
    meta: {
      pageTitle: 'Conservación',
      resource: 'TECNICO',
      action: 'manage',
      children: [
        {
          path: '/conservacion-formulario/:poste',
          name: 'conservacion-formulario',
          component: () => import('@/views/mantenimiento/conservacion/conservacionFormulario.vue'),
          meta: { navActiveLink: 'conservacion-formulario', params: { poste: ':poste' } },
        },
      ],
    },
  },
  {
    path: '/conservacion-formulario/:poste',
    name: 'conservacion-formulario',
    component: () => import('@/views/mantenimiento/conservacion/conservacionFormulario.vue'),
    meta: {
      navActiveLink: 'conservacion',
      exact: true,
      params: { poste: ':poste' },
      pageTitle: 'Conservación Formulario',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/edicion-conservacion/:id',
    name: 'edicion-conservacion',
    component: () => import('@/views/mantenimiento/conservacion/conservacionEdicion.vue'),
    meta: {
      pageTitle: 'Edición Conservación',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
