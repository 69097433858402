export default [
  // RUTAS INSPECCIONES
  {
    path: '/inspecciones/rutas',
    name: 'inspecciones-rutas',
    component: () => import('@/views/inspecciones/rutas/Ruta.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'MONITOREO',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Rutas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/show/:id/:origen/:action',
    name: 'inspecciones-show',
    component: () => import('@/views/inspecciones/inspecciones/Show.vue'),
    meta: {
      pageTitle: 'Punto de Iluminación',
      navActiveLink: 'puntos-iluminacion',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inspecciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/nocturnas',
    name: 'inspecciones-nocturnas',
    component: () => import('@/views/inspecciones/inspecciones/Nocturnas.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'INSPECCIONES',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inspecciones Nocturnas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/diurnas',
    name: 'inspecciones-diurnas',
    component: () => import('@/views/inspecciones/inspecciones/Diurnas.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'INSPECCIONES',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inspecciones Diurnas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/rutas-show/:id/:origen/:action',
    name: 'inspecciones-rutas-show',
    component: () => import('@/views/inspecciones/rutas/DetailRuta.vue'),
    meta: {
      pageTitle: 'Detalle de la Ruta',
      navActiveLink: 'inspecciones-rutas',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Localidades',
          url: '/inspecciones/rutas',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/ampliaciones',
    name: 'inspecciones-ampliaciones',
    component: () => import('@/views/inspecciones/ampliaciones/Ampliaciones.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'AMPLIACIONES',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Ampliaciones Puntos Nuevos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/ampliaciones-autorizadas',
    name: 'ampliaciones-autorizadas',
    component: () => import('@/views/inspecciones/ampliaciones/ampliaciones-autorizadas.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'AMPLIACIONES',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Ampliaciones Autorizadas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/ampliacion',
    name: 'inspecciones-ampliacion',
    component: () => import('@/views/inspecciones/ampliaciones/AmpliacionesInspector.vue'),
    meta: {
      pageTitle: 'Ampliaciones',
      resource: 'INSPECCIONES',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Ampliaciones Puntos Nuevos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/reporte/',
    name: 'inspecciones-reporte',
    component: () => import('@/views/inspecciones/inspecciones/Reporte.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Inspecciones Diurnas y Nocturas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/autorizaciones',
    name: 'inspecciones-autorizaciones',
    component: () => import('@/views/inspecciones/ampliaciones/AutorizacionesAmpliaciones.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Autorizaciones de Puntos Nuevos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/facturacion',
    name: 'inspecciones-facturacion',
    component: () => import('@/views/inspecciones/ampliaciones/Facturacion.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Facturación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inspecciones/por-cobrar',
    name: 'inspecciones-porCobrar',
    component: () => import('@/views/inspecciones/ampliaciones/por-cobrar.vue'),
    meta: {
      pageTitle: 'Inspecciones',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Por Cobrar',
          active: true,
        },
      ],
    },
  },
]
