import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon } from '@iconify/vue2'

import NoAutoComplete from 'vue-no-autocomplete'
// import { datadogRum } from '@datadog/browser-rum'
// import { datadogLogs } from '@datadog/browser-logs'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Stylesheets DevExtreme
import 'devextreme/dist/css/dx.light.css'

// 3rd party plugins
// AGREGAR ESTO PARA QUE FUNCIONEN LOS $
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Iconify Vue 2 Componente
Vue.component('Icon', Icon)

/* datadogRum.init({
  applicationId: '3733387d-5340-423c-9f19-fd568e2f0706',
  clientToken: 'pubc91d2a83519112492d40f8a3c71f4f57',
  site: 'datadoghq.com',
  service: 'ap-sistema',
  env: 'prod',
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  trackInteractions: true,
})

datadogLogs.init({
  clientToken: 'pubc91d2a83519112492d40f8a3c71f4f57',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
}) */

Vue.use(NoAutoComplete)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
