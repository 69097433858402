export default [
  {
    path: '/tickets-asignados',
    name: 'tickets-asignados',
    component: () => import('@/views/migraciones/TicketsAsignados.vue'),
    meta: {
      pageTitle: 'Sustituciones',
      resource: 'MIGRACION',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Asignadas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/entrega-luminarias',
    name: 'entrega-luminarias',
    component: () => import('@/views/entregaLuminarias/indexEntregaLuminarias.vue'),
    meta: {
      pageTitle: 'Luminarias Recolectadas',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Luminarias Recolectadas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tickets-entrega',
    name: 'tickets-entrega',
    component: () => import('@/views/entregaLuminarias/indexTicketsEntrega.vue'),
    meta: {
      pageTitle: 'Entrega de Luminarias',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Entrega de Luminarias',
          active: true,
        },
      ],
    },
  },
]
