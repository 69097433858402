export default [
  {
    path: '/conservacion-gabinetes',
    name: 'conservacion-gabinetes',
    component: () => import('@/views/mantenimiento/conservacionGabinetes/conservacionGabinetesIndex.vue'),
    meta: {
      pageTitle: 'Conservación Centros de Mando P&M',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
  {
    path: '/conservacion-gabinetes-formulario/:gabinete',
    name: 'conservacion-gabinetes-formulario',
    component: () => import('@/views/mantenimiento/conservacionGabinetes/conservacionGabinetesFormulario.vue'),
    meta: {
      navActiveLink: 'conservacion-gabinetes',
      pageTitle: 'Formulario de Conservación Centro de Mando P&M',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
