export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/dashboard/Main.vue'),
    meta: {
      pageTitle: 'Dashboard',
      resource: 'DASHBOARD',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Reportes de Estadísticas',
          active: true,
        },
      ],
    },
  },
]
