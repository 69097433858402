// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

const rootRoles = ['SUPER', 'ADMIN', 'ADMINISTRATOR', 'REPORTES', 'MIGRACION', 'MONITOREO', 'INVITADO', 'LEVANTAMIENTO', 'BODEGA']

export const getHomeRouteForLoggedInUser = userRole => {
  if (rootRoles.includes(userRole)) return '/'
  if (userRole === 'SUPERVISOR') return '/tickets'
  if (userRole === 'INSPECCIONES') return '/inspecciones/nocturnas'
  if (userRole === 'MUNICIPALIDAD') return '/inspecciones/autorizaciones'
  if (userRole === 'TECNICO') return '/fallas'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}
