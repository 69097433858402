// eslint-disable-next-line import/prefer-default-export
import { getUserData } from '@/auth/utils'

function hasPassedOneDay(storedDateValue) {
  const user = getUserData()
  if (!storedDateValue) return true

  const storedDate = new Date(Number(storedDateValue))
  const specificDate = new Date(user.company.requestLogin)
  const staticCurrentDate = new Date('2024-08-28T15:48:00')

  console.log(staticCurrentDate)
  console.log(storedDate)
  console.log(storedDate < staticCurrentDate)

  if (storedDate < staticCurrentDate) {
    return true
  }

  if (storedDate < specificDate) {
    return true
  }

  const currentDate = new Date()
  const diffInMilliseconds = currentDate - storedDate
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000
  return diffInMilliseconds >= oneDayInMilliseconds
}

// eslint-disable-next-line import/prefer-default-export
export { hasPassedOneDay }
