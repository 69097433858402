export default [
  {
    path: '/informe-mensual',
    name: 'informe-mensual-index',
    component: () => import('@/views/mantenimiento/informeMensual/informe-mensual-index.vue'),
    meta: {
      pageTitle: 'Informe Mensual',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
