export default [
  // EMPRESAS
  {
    path: '/administracion/empresas',
    name: 'empresas',
    component: () => import('@/views/administracion/Empresas/Empresas.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Empresas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/empresa-show/:id/:origen/:action',
    name: 'catalogos-empresa-show',
    component: () => import('@/views/administracion/Empresas/DetailEmpresa.vue'),
    meta: {
      pageTitle: 'Detalle de la Empresa',
      navActiveLink: 'empresas',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Empresas',
          url: '/administracion/empresas',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DEPARTAMENTOS
  {
    path: '/localizacion/departamentos',
    name: 'localizacion-departamentos',
    component: () => import('@/views/administracion/Departamentos/Departamentos.vue'),
    meta: {
      pageTitle: 'Cátalogos',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Departamentos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/localizacion/departamentos-show/:id/:origen/:action',
    name: 'catalogos-departamentos-show',
    component: () => import('@/views/administracion/Departamentos/DetailDepartamento.vue'),
    meta: {
      pageTitle: 'Detalle del Departamento',
      navActiveLink: 'localizacion-departamentos',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Departamentos',
          url: '/localizacion/departamentos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // MUNICIPIOS
  {
    path: '/localizacion/municipios',
    name: 'localizacion-municipios',
    component: () => import('@/views/administracion/Municipios/Municipios.vue'),
    meta: {
      pageTitle: 'Cátalogos',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Municipios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/localizacion/municipios-show/:id/:origen/:action',
    name: 'catalogos-municipios-show',
    component: () => import('@/views/administracion/Municipios/DetailMunicipio.vue'),
    meta: {
      pageTitle: 'Detalle del Municipio',
      navActiveLink: 'localizacion-municipios',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Municipios',
          url: '/localizacion/municipios',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // LOCALIDADES
  {
    path: '/localizacion/localidades',
    name: 'localizacion-localidades',
    component: () => import('@/views/administracion/Localidades/Localidades.vue'),
    meta: {
      pageTitle: 'Cátalogos',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Localidades',
          active: true,
        },
      ],
    },
  },
  {
    path: '/localizacion/localidades-show/:id/:origen/:action',
    name: 'catalogos-localidades-show',
    component: () => import('@/views/administracion/Localidades/DetailLocalidad.vue'),
    meta: {
      pageTitle: 'Detalle de la Localidad',
      navActiveLink: 'localizacion-localidades',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Localidades',
          url: '/localizacion/localidades',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // TECNOLOGIA LUMINARIA
  {
    path: '/administracion/tec-luminaria',
    name: 'catalogos-tec-luminaria',
    component: () => import('@/views/administracion/tecnologiaLuminaria/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tecnología Lámpara',
          active: true,
        },
      ],
    },
  },
  // CLASE LAMPARA
  {
    path: '/administracion/clase-lampara',
    name: 'catalogos-clase-lampara',
    component: () => import('@/views/administracion/modeloLampara/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Clase de Lámparas',
          active: true,
        },
      ],
    },
  },
  // ESTADOS LAMPARA
  {
    path: '/administracion/estados-lampara',
    name: 'catalogos-estados-lampara',
    component: () => import('@/views/administracion/estadoLampara/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Estados de Lámparas',
          active: true,
        },
      ],
    },
  },
  // TIPO POSTES
  {
    path: '/administracion/tipo-poste',
    name: 'catalogos-tipo-poste',
    component: () => import('@/views/administracion/tiposPoste/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tecnología Luminaria',
          active: true,
        },
      ],
    },
  },
  // TIPO POSTES
  {
    path: '/administracion/tipo-poste-levantamiento',
    name: 'catalogos-tipo-poste-levantamiento',
    component: () => import('@/views/administracion/tiposPosteLevantamiento/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipo de Poste Levantamiento',
          active: true,
        },
      ],
    },
  },
  // TIPO FALLAS
  {
    path: '/administracion/tipo-falla',
    name: 'catalogos-tipo-falla',
    component: () => import('@/views/administracion/tiposFallas/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipos de Falla',
          active: true,
        },
      ],
    },
  },
  // TIPO LÁMPARA BODEGA
  {
    path: '/administracion/tipo-lampara-bodega',
    name: 'catalogos-tipo-lampara-bodega',
    component: () => import('@/views/administracion/tipoLamparaBodegas/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipo de Lámparas de Bodega',
          active: true,
        },
      ],
    },
  },
  // TIPO FALLAS bt
  {
    path: '/administracion/tipo-falla-bt',
    name: 'catalogos-tipo-falla-bt',
    component: () => import('@/views/administracion/fallasBT/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipos de Falla',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG MARCA
  {
    path: '/administracion/edit-falla-bt/:id/:origen/:action',
    name: 'catalogos-edit-falla-bt',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-falla-bt',
      pageTitle: 'Tipos de Falla BT',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // TIPO CONSERVACIÓN
  {
    path: '/administracion/trabajos-conservacion',
    name: 'catalogos-trabajos-conservacion',
    component: () => import('@/views/administracion/conservacion/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Trabajos de Conservación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/trabajos-conservacion/:id/:origen/:action',
    name: 'catalogos-edit-trabajos-conservacion',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-trabajos-conservacion',
      pageTitle: 'Trabajos Conservación',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // HERRAJES
  {
    path: '/administracion/herrajes',
    name: 'catalogos-herrajes',
    component: () => import('@/views/administracion/herrajes/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipos de Herrajes',
          active: true,
        },
      ],
    },
  },
  // TAMAÑO ACCESORIOS
  {
    path: '/administracion/tamanos-accesorios',
    name: 'catalogos-tamano-accesorios',
    component: () => import('@/views/administracion/tamanosAccesorios/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tamaños de Accesorios',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG TAMAÑO DE ACCESORIOS
  {
    path: '/administracion/edit-tamanos-accesorios/:id/:origen/:action',
    name: 'catalogos-edit-tamanos-accesorios',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tamano-accesorios',
      pageTitle: 'Tamaño de Accesorios',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // CONEXIONES
  {
    path: '/administracion/conexiones',
    name: 'catalogos-tipo-conexion',
    component: () => import('@/views/administracion/conexiones/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipos de Conexiones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/tipos-falla/:id/:origen/:action',
    name: 'catalogos-edit-tipo-falla',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-falla',
      pageTitle: 'Tipos de Falla',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // MARCAS
  {
    path: '/administracion/marcas',
    name: 'catalogos-marcas',
    component: () => import('@/views/administracion/marcas/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Marcas',
          active: true,
        },
      ],
    },
  },
  // POTENCIA LAMPARA
  {
    path: '/administracion/potencias',
    name: 'catalogos-potencia-lampara',
    component: () => import('@/views/administracion/potencias/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Potencias',
          active: true,
        },
      ],
    },
  },
  // PROPIEDADES
  {
    path: '/administracion/propiedades',
    name: 'catalogos-propiedades',
    component: () => import('@/views/administracion/propiedades/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Propiedades',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG TIPO POSTE
  {
    path: '/administracion/edit-tipo-poste/:id/:origen/:action',
    name: 'catalogos-edit-tipo-poste',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-poste',
      pageTitle: 'Tipo de Poste',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG TIPO POSTE
  {
    path: '/administracion/edit-tipo-poste-levantamiento/:id/:origen/:action',
    name: 'catalogos-edit-tipo-poste-levantamiento',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-poste-levantamiento',
      pageTitle: 'Tipo de Poste Levantamiento',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG TIPO CONEXIONES
  {
    path: '/administracion/edit-tipo-conexiones/:id/:origen/:action',
    name: 'catalogos-edit-conexiones',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-conexion',
      pageTitle: 'Tipo de Conexiones',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG TIPO HERRAJE
  {
    path: '/administracion/edit-catalogos-edit-herraje/:id/:origen/:action',
    name: 'catalogos-edit-herraje',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-herrajes',
      pageTitle: 'Tipo de Herrajes',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG POTENCIAS
  {
    path: '/administracion/edit-tipo-poste/:id/:origen/:action',
    name: 'catalogos-edit-potencias',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-potencia-lampara',
      pageTitle: 'Potencias Lámpara',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG TEC LUMINARIA
  {
    path: '/administracion/edit-tec-luminaria/:id/:origen/:action',
    name: 'catalogos-edit-tec-luminaria',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tec-luminaria',
      pageTitle: 'Tecnología Lámpara',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG CLASE LAMPARA
  {
    path: '/administracion/edit-clase/:id/:origen/:action',
    name: 'catalogos-edit-clase',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-clase-lampara',
      pageTitle: 'Clase de Lampara',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG ESTADOS LAMPARA
  {
    path: '/administracion/edit-estado-lampara/:id/:origen/:action',
    name: 'catalogos-edit-estado-lampara',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-estados-lampara',
      pageTitle: 'Estados Lampara',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG MARCA
  {
    path: '/administracion/edit-marca/:id/:origen/:action',
    name: 'catalogos-edit-marcas',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-marcas',
      pageTitle: 'Marca',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // DETAIL CATALOG PROPIEDAD
  {
    path: '/administracion/edit-propiedades/:id/:origen/:action',
    name: 'catalogos-edit-propiedades',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-propiedades',
      pageTitle: 'Propiedades',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // PUNTOS DE ILIMUNACION
  {
    path: '/puntos-iluminacion',
    name: 'puntos-iluminacion',
    component: () => import('@/views/postes/Postes.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'PUNTOS',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Puntos de Ilumación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/puntos-iluminacion-show/:id/:origen/:action',
    name: 'puntos-iluminacion-show',
    component: () => import('@/views/postes/DetailPoste.vue'),
    meta: {
      pageTitle: 'Detalle del Punto de Iluminación',
      navActiveLink: 'puntos-iluminacion',
      resource: 'PUNTOS',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Puntos de Ilumación',
          url: '/puntos-iluminacion',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/puntos-iluminacion/levantamiento/:id',
    name: 'puntos-iluminacion-levantamiento',
    component: () => import('@/views/postes/ViewLevantamientoDeDatos.vue'),
    meta: {
      pageTitle: 'Levantamiento Punto',
      navActiveLink: 'puntos-iluminacion',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Puntos de Ilumación',
          url: '/puntos-iluminacion',
        },
        {
          text: 'Ingreso de Información',
          active: true,
        },
      ],
    },
  },
  {
    path: '/puntos-location',
    name: 'postes-location',
    component: () => import('@/views/postes/Mapa.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'PUNTOS',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Ubicación de Puntos de Ilumación',
          active: true,
        },
      ],
    },
  },
  // TIPO LAMPARAS
  {
    path: '/administracion/tipo-lamparas',
    name: 'catalogos-tipo-lamparas',
    component: () => import('@/views/administracion/tiposLamparas/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipo Lamparas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/edit-tipo-lamparas/:id/:origen/:action',
    name: 'catalogos-edit-tipo-lamparas',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-lamparas',
      pageTitle: 'Tipo de Lampara',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // TIPO DE DISPOSITIVO LAMPARA
  {
    path: '/administracion/dispositivos-lampara',
    name: 'catalogos-dispositivo-lampara',
    component: () => import('@/views/administracion/DispositivoLampara/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'LEVANTAMIENTO_INICIAL',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Dispositivos Lámparas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/dispositivos-lampara/:id/:origen/:action',
    name: 'catalogos-edit-dispositivo-lampara',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-dispositivo-lampara',
      pageTitle: 'Dispositivos Lámparas',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // UNIDADES VARIAS
  {
    path: '/administracion/unidades-varias',
    name: 'catalogos-unidades-varias',
    component: () => import('@/views/administracion/unidadesVarias/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Unidades Varias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/unidades-varias/:id/:origen/:action',
    name: 'catalogos-edit-unidades-varias',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-unidades-varias',
      pageTitle: 'Unidades Varias',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // TRABAJOS LEVANTAMIENTO
  {
    path: '/administracion/trabajos-levantamiento',
    name: 'catalogos-trabajos-levantamiento',
    component: () => import('@/views/administracion/unidadesVarias/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Trabajos Levantamiento',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/trabajos-levantamiento/:id/:origen/:action',
    name: 'catalogos-edit-trabajos-levantamiento',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-trabajos-levantamiento',
      pageTitle: 'Trabajos Levantamiento',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Catálogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // GRUPOS
  {
    path: '/administracion/grupos',
    name: 'catalogos-grupos',
    component: () => import('@/views/administracion/grupo/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Grupos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/grupo/:id/:origen/:action',
    name: 'catalogos-edit-grupo',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-grupos',
      pageTitle: 'Grupos',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // SISTEMA DE COMUNICACIÓN
  {
    path: '/administracion/sistema-comunicacion',
    name: 'catalogos-sistema-comunicacion',
    component: () => import('@/views/administracion/sistemaComunicacion/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Sistema Comunicación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/sistema-comunicacion/:id/:origen/:action',
    name: 'catalogos-edit-sistema-comunicacion',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-sistema-comunicacion',
      pageTitle: 'Grupos',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // SISTEMA DE COMUNICACIÓN
  {
    path: '/administracion/tipo-brazos',
    name: 'catalogos-tipo-brazo',
    component: () => import('@/views/administracion/tiposBrazos/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Tipos de Brazo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/tipo-brazo/:id/:origen/:action',
    name: 'catalogos-edit-tipo-brazo',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-tipo-brazo',
      pageTitle: 'Tipo de Brazo',
      resource: 'CATALOGO_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador-gabinetes',
    name: 'administrador-gabinetes',
    component: () => import('@/views/mantenimiento/conservacionGabinetes/admin/admin-gabinetes.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Administrador de Centros de Mando P&M',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador-control-gabinetes-show',
    name: 'administrador-gabinetes-show',
    component: () => import('@/views/mantenimiento/conservacionGabinetes/admin/admin-gabinetes-show.vue'),
    meta: {
      navActiveLink: 'administrador-gabinetes',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Administrador de Centros de Mando P&M',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador-gateway',
    name: 'administrador-gateway',
    component: () => import('@/views/mantenimiento/conservacionGateway/admin/admin-gateway.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Administrador Gateway',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador-gateway-show',
    name: 'administrador-gateway-show',
    component: () => import('@/views/mantenimiento/conservacionGateway/admin/admin-gateway-show.vue'),
    meta: {
      navActiveLink: 'administrador-gateway',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Administrador Gateway',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador-metricas',
    name: 'administrador-metricas',
    component: () => import('@/views/administracion/Metricas/metricas-index.vue'),
    meta: {
      navActiveLink: 'administrador-metricas',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Administrador Gateway',
          active: true,
        },
      ],
    },
  },
  // Catálogo Trabajos Brecha
  {
    path: '/administracion/trabajos-brecha',
    name: 'catalogos-trabajos-brecha',
    component: () => import('@/views/administracion/trabajosBrecha/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Trabajos de Brecha de Iluminación',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/trabajos-brecha/:id/:origen/:action',
    name: 'catalogos-edit-trabajos-brecha',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-trabajos-brecha',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Trabajos Gateway
  {
    path: '/administracion/trabajos-gateway',
    name: 'catalogos-trabajos-gateway',
    component: () => import('@/views/administracion/trabajosGateway/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Trabajos de Conservación Gateway',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/trabajos-gateway/:id/:origen/:action',
    name: 'catalogos-edit-trabajos-gateway',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-trabajos-gateway',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Trabajos Gabinete
  {
    path: '/administracion/trabajos-gabinete',
    name: 'catalogos-trabajos-gabinete',
    component: () => import('@/views/administracion/trabajosGabinetes/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Trabajos de Conservación Centros de Mando P&M',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/trabajos-gabinete/:id/:origen/:action',
    name: 'catalogos-edit-gabinete',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-trabajos-gabinete',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Fallas Inicio Ticket
  {
    path: '/administracion/fallas-iniciales-ticket',
    name: 'catalogos-fallas-iniciales-ticket',
    component: () => import('@/views/administracion/fallasInicialesTicket/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Fallas Iniciales Ticket',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/fallas-iniciales-ticket/:id/:origen/:action',
    name: 'catalogos-edit-fallas',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-fallas-iniciales-ticket',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Fallas Lámparas Bodega
  {
    path: '/administracion/fallas-lamparas-bodega',
    name: 'catalogos-fallas-lamparas-bodega',
    component: () => import('@/views/administracion/fallasLamparasBodega/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Fallas Lámparas de Bodega',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/fallas-lamparas-bodega/:id/:origen/:action',
    name: 'catalogo-edit-bodega',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-fallas-lamparas-bodega',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Pre-Diagnóstico Lámparas Bodega
  {
    path: '/administracion/prediagnostico-lamparas-bodega',
    name: 'catalogos-prediagnostico-lamparas-bodega',
    component: () => import('@/views/administracion/preDiagnosticoBodega/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pre-Diagnóstico de Lámparas de Bodega',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/prediagnostico-lamparas-bodega/:id/:origen/:action',
    name: 'catalogos-edit-prediagnostico-lamparas-bodega',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-prediagnostico-lamparas-bodega',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Pruebas Lámparas Bodega
  {
    path: '/administracion/pruebas-lamparas-bodega',
    name: 'catalogos-pruebas-lamparas-bodega',
    component: () => import('@/views/administracion/pruebasLamparasBodega/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pruebas de Lámparas de Bodega',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/pruebas-lamparas-bodega/:id/:origen/:action',
    name: 'catalogos-edit-pruebas-lamparas-bodega',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-pruebas-lamparas-bodega',
      pageTitle: 'Administración',
      resource: 'ADMIN_ANY',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Reparaciones Laboratorio
  {
    path: '/administracion/reparaciones-laboratorio',
    name: 'catalogos-reparaciones-laboratorio',
    component: () => import('@/views/administracion/reparacionesLaboratorio/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pruebas de Lámparas de Bodega',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/reparaciones-laboratorio/:id/:origen/:action',
    name: 'catalogos-edit-reparaciones-laboratorio',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-pruebas-lamparas-bodega',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
  // Catálogo Trabajos Correctivos
  {
    path: '/administracion/trabajos-correctivos',
    name: 'catalogos-trabajos-correctivos',
    component: () => import('@/views/administracion/trabajosCorrectivos/Listado.vue'),
    meta: {
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Trabajos Correctivos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administracion/trabajos-correctivos/:id/:origen/:action',
    name: 'catalogos-edit-trabajos-correctivos',
    component: () => import('@/components/DetailCatalog.vue'),
    meta: {
      navActiveLink: 'catalogos-trabajos-correctivos',
      pageTitle: 'Administración',
      resource: 'ADMIN',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Cátalogos',
        },
        {
          text: 'Detalle',
          active: true,
        },
      ],
    },
  },
]
