export default [
  {
    path: '/dashboard-bodega',
    name: 'dashboard-bodega',
    component: () => import('@/views/bodega/dashboard/dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard de Bodega',
      resource: 'TECNICO',
      action: 'manage',
      breadcrumb: [
      ],
    },
  },
]
